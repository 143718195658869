.row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row .columns {
  height: auto;
  display: inline-block;
  margin-bottom: 8px;
}
.row .columns .smart-image,
.row .columns .smart-image .div-1 {
  height: auto !important;
}
.row .div-1 .div-2 {
  margin-top: 5px !important;
}
