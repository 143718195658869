.Jobs {
  width: 100%;
  font-weight: 500;
}

.Jobs .jobs-header {
  width: 100%;
  display: flex;
  align-content: space-between;
}

.jobs-header .jobs-header__title {
  width: 100%;
  font-size: 30px;
  font-weight: 600;
}

.Jobs > nav.MuiPagination-root {
  display: flex;
  justify-content: center;
}

.job-date {
  font-size: 1.3rem;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .jobs-header {
    flex-direction: column;
    align-items: center;
  }
}
