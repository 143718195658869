.gs-h1 {
  padding: 18px;
  font-size: 32px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.video-container {
  padding: 56.25% 0 0 0;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.gs-header {
  font-weight: bold;
  margin: 20px 0;
}

.gs-h2 {
  font-size: 24px;
  color: #5047ff;
}

.gs-h3 {
  font-size: 20px;
  color: black;
}

.gs-content {
  padding: 0 18px;
}

.intro-text {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.3;
}

.intro-text a {
  font-size: 16px !important;
}

.tutorial-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorial-overlay__content {
  position: relative;
  min-height: 250px;
  width: 80%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tutorial-close {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.item-title {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px;
  background-color: #f7f6ff;
  font-size: 24px;
  border-radius: 5px 5px 0 0;
}

.item-title svg {
  height: 40px;
  width: 40px;
}

.item-description {
  padding: 20px;
  font-size: 16px;
  margin: 0;
}

.page-button {
  display: block;
  border: none;
  border-radius: 30px;
  padding: 10px;
  color: white;
  font-size: 14px;
  background-color: #5047ff;
  min-width: 80px;
  cursor: pointer;
}

.page-button--back {
  float: left;
}

.page-button--next {
  float: right;
}

.page-button--tour {
  margin: 15px 0;
}

.button-container {
  padding: 15px;
}

@media only screen and (min-width: 768px) {
  .tutorial-overlay__content {
    width: 50%;
  }
}
