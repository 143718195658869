.account .g-form .title {
  font-weight: 600;
  margin-bottom: 6px;
}

.noscroll {
  overflow: hidden;
  height: 100%;
}

.account .content {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) {
  .account .content {
    margin-top: 0;
    margin-left: 262px;
    width: calc(100vw - 262px);
    box-sizing: border-box;
  }
}
