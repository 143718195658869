.properties-container {
  width: 80%;
}

.properties-editor-container {
  height: 70vh;
  min-height: 400px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.properties-editor {
  padding-top: 5px;
}

.properties-editor div.jsoneditor-menu {
  background-color: #635bff;
}

.ace-jsoneditor .ace_content div,
.ace-jsoneditor ace_text-input,
.ace-jsoneditor .ace_gutter div,
.ace-jsoneditor .ace_scroller div,
.ace-jsoneditor .ace_scrollbar div,
.ace-jsoneditor-statusbar div {
  font-size: 1.4rem;
}
