@import './variable.css';

html {
  background-color: var(--color-dark-white);
  height: 100%;
  font-size: 62.5%;
}
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  font-family: Helvetica, Arial, sans-serif;
  font-size: var(--font-size-base);
}

code {
  font-family: 'Fira Sans', sans-serif;
}
.text-strongest {
  font-weight: 700;
  color: var(--color-dark-grey);
}
.text-weak {
  color: var(--color-grey);
}

.text-wrap {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  width: 100%;
  font-size: 1.3rem;
}

.list-pointer {
  cursor: pointer;
}
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list-pointer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

a:hover {
  cursor: pointer;
}

/* Global Styles  */

.g-full-width {
  width: 100%;
}

.g-input-height {
  height: 78px;
}
.g-input-width-16 {
  width: 16%;
}

.g-form {
  border: solid 1px #eaeaea;
  border-radius: var(--border-radius-form);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .g-form {
    padding: 20px 30px 0px 30px;
    width: 100%;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .g-form {
    padding: 20px 30px 40px 30px;
    width: 400px;
  }
}

.g-form button {
  margin-top: 26px;
}

.g-form .icon-btn_password button {
  margin-top: 0px;
}

.g-link {
  color: #4122af;
}

.g-error {
  color: #ff8487;
}

.data-count {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  padding-left: 24px;
  color: var(--color-grey);
  height: 60px;
  font-size: 1.3rem;
  text-align: left;
}

.g-page-name {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-black);
  width: calc(100% - 40px);
  text-align: center;
  display: inline-block;
  line-height: 28px;
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .g-page-name {
    text-align: left;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .g-page-without-header-footer {
    width: 100%;
    height: 100%;
  }
}

.g-page-without-header-footer .logo img {
  width: 160px;
}
.g-page-without-header-footer h1 {
  text-align: center;
  margin: 20px auto 40px auto;
}

/* Utils */

.u-flex-1 {
  flex: 1;
}

.u-flex {
  display: flex;
}

.u-flex-row {
  display: flex;
  flex-direction: row;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
}

.u-align-center {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-align-vertical-center {
  display: flex;
  align-items: center;
}

.u-align-horizontal-center {
  display: flex;
  justify-content: center;
}

.u-text-align-right {
  text-align: right;
}

.u-margin-top-small {
  margin-top: 6px !important;
}

.u-margin-bottom-small {
  margin-bottom: 6px !important;
}

.u-margin-top-medium {
  margin-top: 12px !important;
}

.u-margin-bottom-medium {
  margin-bottom: 12px !important;
}

.u-margin-top-large {
  margin-top: 18px !important;
}

.u-margin-top-xlarge {
  margin-top: 36px !important;
}

.u-margin-bottom-large {
  margin-bottom: 18px !important;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .u-padding-medium {
    padding: 0px !important;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .u-padding-medium {
    padding: 12px !important;
  }
}

.u-padding-top-medium {
  padding-top: 12px !important;
}

.u-padding-bottom-medium {
  padding-bottom: 12px !important;
}

.u-margin-right-small {
  margin-right: 6px !important;
}
.u-margin-right-medium {
  margin-right: 12px !important;
}
.u-margin-right-large {
  margin-right: 18px !important;
}
.link-without-underline {
  text-decoration: none;
}

.MuiAlert-filledError {
  background-color: var(--color-red) !important;
}
.MuiAlert-filledWarning {
  background-color: var(--color-orange) !important;
}
.MuiAlert-filledSuccess {
  background-color: var(--color-green) !important;
}
.MuiAlert-filledInfo {
  background-color: var(--color-blue) !important;
}
.form-checkbox .MuiFormControlLabel-label {
  font-size: 1.6rem;
}
.form-checkbox-label {
  font-size: 1.1rem;
  color: #7f90a0;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .filters-container-container {
    width: 98%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .filters-container-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .filters-container {
    border: solid 1px #eaeaea;
    border-radius: var(--border-radius-form);
    background-color: var(--color-white);
    display: inline-block;
    /*padding: 5px 0 25px 10px;*/
    width: 100%;
    padding: 5px 5px 5px 20px;
    width: 90%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .grid-class-container {
    display: grid;
    gap: 12px;
  }
  .grid__col {
    grid-column: span 8;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .filters-container {
    border: solid 1px #eaeaea;
    border-radius: var(--border-radius-form);
    margin: 0 20px 20px 20px;
    background-color: var(--color-white);
    display: inline-block;
    padding: 5px 0 25px 10px;
  }
  .grid-class-container {
    display: grid;
    grid-template-columns: repeat(60, 1fr);
    gap: 12px;
  }
  .grid__col {
    grid-column: span 9;
  }
}

.form-checkbox {
  color: var(--color-grey);
  margin-top: 10px !important;
}

.MuiSelect-root,
.MuiInputBase-root,
.MuiInput-input,
.MuiInputBase-inputAdornedEnd {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.3rem !important;
}

.MuiButton-label {
  font-size: 1.3rem;
}

.MuiTab-wrapper {
  font-size: 1.3rem;
}
.MuiInputBase-input {
  color: var(--color-black) !important;
}

.MuiPopover-paper {
  background-color: #fff !important;
}
.MuiPickersBasePicker-container {
  background-color: #fff !important;
}
.MuiButton-root {
  text-transform: none !important;
}
.MuiButton-containedPrimary:hover {
  background-color: var(--color-light-purple) !important;
}
.MuiButton-outlinedPrimary:hover {
  background-color: var(--color-purple) !important;
  color: var(--color-white) !important;
}
.MuiPaginationItem-page {
  border-color: var(--color-light-grey) !important;
  color: var(--color-dark-grey) !important;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: var(--color-dark-grey) !important;
  color: var(--color-light-grey) !important;
}
.MuiPaginationItem-page:hover {
  background-color: var(--color-dark-grey) !important;
  color: var(--color-light-grey) !important;
}

.alice-carousel__dots {
  margin: 0;
}
.alice-carousel__dots-item {
  background-color: var(--color-light-grey) !important;
}
.alice-carousel__dots-item.__active {
  background-color: var(--color-grey) !important;
}

.filters__container .filters {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;
  margin-top: 30px;
}

.filters__container .filters .MuiSvgIcon-root {
  font-size: 2.4rem;
}

.MuiAutocomplete-option {
  font-size: 1.4rem;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}
