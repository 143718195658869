.subscription-confirm-container {
  margin-top: 2.6em;
}

.location-field {
  margin-left: 1.3em;
  margin-right: 1.3em;
}
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .update-account-main {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    padding: 10px;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .update-account-main {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: calc(1vh + 12px);
  }
}

.update-account-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4vh;
}

@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .update-account-tabs {
    width: 50%;
  }
}
