geo-locate-label.geolookup {
  font-size: 18px;
  font-size: 1.3rem;
  position: relative;
  text-align: left;
  margin-bottom: 5vh;
}
.geolookup__input {
  width: calc(100% - 2em);
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition:
    border 0.2s,
    box-shadow 0.2s;
  transition:
    border 0.2s,
    box-shadow 0.2s;
  outline: none;
}
.geolookup__input:focus {
  border: 2px solid #635bff;
  box-shadow: 0 0 0 transparent;
}
.geolookup__input:hover {
  border: 2px solid #635bff;
}
.geolookup__input--nominatim {
  width: 75%;
}
.geolookup__button {
  width: 25%;
  padding: 0.8em 0.5em 0.6em 0.5em;
  font-size: 16px;
}
.geolookup__suggests {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 32.5em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #635bff;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition:
    max-height 0.2s,
    border 0.2s;
  transition:
    max-height 0.2s,
    border 0.2s;
  width: 100%;
}

.geolookup__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geolookup__item {
  font-size: 18px;
  font-size: 1.3em;
  padding: 0.65em 0.845em;
  cursor: pointer;
}
.geolookup__item:hover,
.geolookup__item:focus {
  background: #f5f5f5;
}
.geolookup__item--active {
  background: #635bff;
  color: #fff;
}
.geolookup__item--active:hover,
.geolookup__item--active:focus {
  background: #ccc;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .geo-locate-label {
    margin-top: 2.6em;
    margin-bottom: 1.3em;
    width: 100%;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .geo-locate-label {
    margin-top: 2.6em;
    margin-bottom: 1.3em;
  }
}
