@import '/src/variable.css';
.fa-download {
  font-size: 16px;
  color: var(--color-purple);
  margin-right: 10px;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .job-modal-ip {
    flex-direction: column;
  }
}

.job-modal-itemLabel {
  width: 25%;
  padding: 20px 5px;
  font-size: 1.3rem;
  line-height: 24px;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .job-modal-itemValue {
    width: 58%;
    padding: 20px 5px;
    font-size: var(--mobile-grid-font);
    line-height: 24px;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .job-modal-itemValue {
    width: 58%;
    padding: 20px 12px;
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .job-modal-itemCheck {
    width: 17%;
    padding: 20px 5px;
    font-size: var(--mobile-grid-font);
    line-height: 24px;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .job-modal-itemCheck {
    width: 17%;
    padding: 20px 12px;
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}

.job-modal-row:hover {
  background-color: var(--color-lighter-grey);
}

.job-modal-row-last {
  border: 1px solid var(--color-light-grey);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.job-modal-row-not-last {
  border-top: 1px solid var(--color-light-grey);
  border-left: 1px solid var(--color-light-grey);
  border-right: 1px solid var(--color-light-grey);
}

.job-modal-row-first {
  border-top: 1px solid var(--color-light-grey);
  border-left: 1px solid var(--color-light-grey);
  border-right: 1px solid var(--color-light-grey);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .collapsableText {
    padding: 20px 5px;
    font-size: var(--mobile-grid-font);
    font-weight: bold;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .collapsableText {
    padding: 20px 12px;
    font-size: var(--font-size-base);
    font-weight: bold;
  }
}

.tab_list {
  margin-top: 40px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.tab_list-scroll {
  overflow: auto;
  height: 100%;
  width: 100%;
}
