@import '/src/variable.css';

.job_modal_wrap {
  background-color: #fff;
  color: var(--color-dark-grey);
  font-size: 14px;
  padding: 20px;
  height: 100vh;
}

.MuiPaper-root {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.MuiCard-root {
  background-color: #fff !important;
}
.MuiPaper-root > ul {
  background-color: #fff;
}
.MuiPaper-root.MuiTableContainer-root {
  background-color: white !important;
}
.MuiTableCell-head {
  font-weight: bold !important;
}
.MuiTab-root {
  font-size: var(--font-size-base) !important;
  color: var(--color-grey) !important;
  font-weight: 700 !important;
}
.MuiTab-root.Mui-selected {
  font-size: var(--font-size-base) !important;
  font-weight: 700 !important;
  color: var(--color-darker-grey) !important;
}
.job-modal__container {
  display: flex;
  gap: 5px;
  flex-direction: column;
  height: 100%;
}

.job-modal__close {
  flex: 0 0 auto;
  cursor: pointer;
}

.job-modal__main > div {
  white-space: nowrap;
}

.job-modal__main {
  height: calc(100vh - 40px);
}

.job-modal__main__info-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.job-modal__main__info-block__photo {
  display: flex;
  height: 442px;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}

.job_modal__main_override_height {
  height: auto !important;
}
.job-modal__main__info-block__photo.job-modal__main__info-block__photo-client {
  height: 370px;
}

.start_not-selected {
  fill: #eaeaea;
}

.start_not-selected:hover {
  fill: rgba(65, 34, 175, 0.5);
}

.start_selected {
  fill: #4122af;
}

.MuiTab-root {
  min-width: initial !important;
}
.MuiTab-wrapper {
  width: initial !important;
}
.MuiTab-textColorInherit.Mui-selected {
  font-weight: bold !important;
}
.MuiTabs-indicator {
  background-color: var(--color-darker-grey) !important;
}

.not-enabled-header {
  text-align: center;
  margin-top: 2.6em;
}

.not-enabled-body {
  white-space: normal;
  max-width: 75%;
  margin: auto;
}

.not-enabled-text {
  font-size: 18px;
}

.job-modal__debug__template-link {
  color: rgb(0, 43, 54);
  letter-spacing: 0.5px;
  vertical-align: top;
  opacity: 0.85;
  text-decoration: none;
  cursor: pointer;
}
.job-modal__debug__template-link:hover {
  text-decoration: underline;
}

.job-modal__main_carousel > div:first-child {
  height: 100%;
}

@media only screen and (min-width: 520px) {
  .job-modal__container {
    flex-direction: row;
  }

  .job-modal__main {
    height: auto;
    width: calc(100vw - 80px);
  }
}
