.support__container {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  vertical-align: center;
  flex-direction: column;
}
.support__body {
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
}
