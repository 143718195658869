@import '/src/variable.css';
.tick {
  cursor: pointer;
}
.tick i {
  font-size: 1.5rem;
}
.tick.removed i {
  color: var(--color-grey);
}
.tick.active i {
  color: var(--color-blue);
}
.tick.approved i {
  color: var(--color-green);
}
.tick.rejected i {
  color: var(--color-red);
}
.tick.warning i {
  color: var(--color-orange);
}
.tick.disabled i {
  color: var(--color-light-grey);
}

.not-applicable-icon {
  max-width: 15px;
}
