@media screen and (max-width: 1200px) {
  .chart-data {
    font-weight: bold;
    font-size: 20px;
  }
}
@media screen and (min-width: 1201px) {
  .chart-data {
    font-weight: bold;
    font-size: 25px;
  }
}

.job-charts {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
}

.job-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.job-chart-header {
  width: 100%;
}

.job-chart-line {
  width: 100%;
  height: 100%;
}

.job-chart-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.job-chart-canvas {
  display: inline-block;
  width: 50%;
}

.job-chart-mappings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.job-chart-mappings-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.job-chart-mappings-colors {
  width: 20px;
  height: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  color: #fff;
}

.job-chart-mappings-text {
  padding: 5px;
}

.job-chart-flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.job-chart-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.job-chart-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
