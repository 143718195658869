.sandbox-container {
  border-top: solid 1px #635bff;
  width: 100%;
  height: 100%;
}

.sandbox-banner {
  z-index: 6;
  border-top: 1px solid #635bff;
  border-radius: 4px 4px 0 0;
  text-align: center;
  line-height: 0;
  pointer-events: none;
}

.sandbox-content {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  height: 14px;
  padding: 2px;
  background-color: #635bff;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
}

.sandbox-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 100%;
  z-index: -1;
}
