@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .manage-subscription-container {
    width: calc(100% - 20px);
    border: solid 1px lightgray;
    padding: 10px;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .manage-subscription-container {
    width: 50%;
    border: solid 1px lightgray;
    padding: 1em;
  }
}
@media screen and (orientation: portrait) and (max-width: 600px) {
  .manage-subscription-main-div {
    width: 100%;
  }
}
@media screen and (orientation: landscape) and (max-height: 600px) {
  .manage-subscription-main-div {
    width: 75%;
  }
}
