.verify-container {
  display: flex;
  margin: auto;
  max-width: 50%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.verify-text {
  font-size: 16px;
  width: 100%;
}

.verify-graphic {
  max-width: 40%;
}

.top-text {
  margin-top: 2.6em;
}

.bottom-text {
  margin-bottom: 2.6em;
}
