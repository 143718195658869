.icon-btn_password {
  margin: 0 !important;
  position: absolute;
  right: 0;
}
.input_error-msg {
  font-size: 1.04em;
  color: #f44336;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  height: 12px;
  display: block;
}
.input_error-label {
  color: #f44336 !important;
}
