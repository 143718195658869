@import '/src/variable.css';

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__container-container-key {
    width: calc(100% + 60px);
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__container-container {
    min-width: var(--min-width-base);
    width: 98%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .list__container-container {
    min-width: var(--min-width-base);
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.list__container {
  border-radius: 4px;
  border: 1px solid var(--color-light-grey);
  padding: 1px;
}

@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .list__container {
    margin: 0 20px 20px 20px !important;
  }
}
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__header {
    color: var(--color-grey);
    font-size: var(--mobile-grid-font);
    font-weight: 600;
    background-color: var(--color-white);
    padding: 20px 3px;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .list__header {
    color: var(--color-grey);
    font-size: 1.1rem;
    font-weight: 600;
    background-color: var(--color-white);
    padding: 20px 12px;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__container-jobs {
    justify-content: space-around;
  }
}

.list__container-header {
  border-bottom: 1px solid var(--color-light-grey);
}

.list__container-body {
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  transition: 0.5s;
  border-top: 1px solid var(--color-light-grey);
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.list__container-body.first {
  border-top: 0px;
  display: flex;
}
.list__container-body.row-colors.odd {
  background-color: var(--color-lightest-grey);
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  /* remove out */
  .list__body {
    padding: 20px 5px;
    font-size: var(--mobile-grid-font);
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .list__body {
    padding: 20px 12px;
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__body__candidates {
    max-width: 20vw !important;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__body__jobs {
    max-width: 19vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list__body__users {
    max-width: 15vw !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.list__container-body:hover {
  background-color: var(--color-lighter-grey);
}

@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .list_span_dot {
    display: flex;
    overflow: hidden;
    align-items: center;
  }
}
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .list_span_dot {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
}
