.hamburger {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: 50px;
  border-bottom: 1px solid lightgrey;
}

.hamburger i {
  padding: 15px;
}

.hamburger img {
  display: block;
  width: 100%;
  height: 30px;
  padding: 10px 0;
}

.close {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
  margin-right: auto;
  padding: 0 15px 15px 0;
}

.sidebar-v2 {
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  box-sizing: border-box;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
}

.logo-container {
  padding: 15px;
  margin-bottom: 5px;
}

.sidebar__logo {
  display: block;
  width: 50%;
  height: auto;
  margin: 0 auto;
}

.nav-link,
.accordion,
.logout {
  display: block;
  text-decoration: none;
  color: #100441 !important;
  margin-bottom: 6px;
}

.nav-link:hover,
.logout:hover {
  background-color: #f7f6ff;
  border-radius: 4px;
  cursor: pointer;
}

.accordion:hover {
  cursor: pointer;
}

.nav-link--active {
  background-color: #f7f6ff;
  border-radius: 4px;
}

.nav-link__items,
.accordion__title,
.logout__items {
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 10px;
  font-size: 1.3rem;
}

.accordion__items .nav-link__items {
  margin-left: 20%;
}

.divider {
  border: 1px solid #e2e1eb;
  margin-bottom: 15px;
}

.divider__label {
  color: #878394;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.logout {
  margin-top: 10px;
}

.profile {
  max-width: 200px;
  margin: 0 auto 25px auto;
}

.profile svg {
  padding: 5px;
  background-color: #f7f6ff;
  border-radius: 5px;
}

.profile .nav-link__items {
  justify-content: center;
}

.profile__names {
  overflow: hidden;
}

.account-user,
.account-name {
  font-size: 1.3rem;
}

.drop-arrow {
  margin-left: auto;
  color: #5047ff;
}

@media only screen and (min-width: 768px) {
  .hamburger,
  .close {
    display: none;
  }

  .sidebar-v2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
    background-color: white;
    box-shadow: 0px 8px 44px #e2dfef80;
    height: 100%;
    width: 262px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 15px;
    box-sizing: border-box;
  }

  .sidebar__logo {
    width: 100%;
  }
}
