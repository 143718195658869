@import '/src/variable.css';

.select-group {
  width: 100%;
  margin-top: 0;
}

@media screen and (orientation: portrait) and (max-width: 600px) {
  .billing-container {
    width: 98%;
  }
}

@media screen and (orientation: landscape) and (max-height: 600px) {
  .billing-container {
    width: 75%;
  }
}
@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .billing-container {
    width: 75%;
  }
}

.save-card-button {
  margin-top: 1.3em;
}

.billing-unavailable {
  margin: auto;
}

.billing__container {
  border: solid 1px #eaeaea;
  border-radius: 5px;
  margin-bottom: 12px;
  width: 98%;
}

@media screen and (orientation: portrait) and (min-width: 601px),
  screen and (orientation: landscape) and (min-height: 601px) {
  .billing__container {
    width: 80%;
    border: solid 1px #eaeaea;
    border-radius: 5px;
    margin-bottom: 12px;
  }
}

.billing__container-head {
  padding: 10px;
  border-bottom: solid 1px #eaeaea;
  display: flex;
  flex-direction: row;
}

.billing__container-body div {
  padding: 6px;
  border-bottom: solid 1px #eaeaea;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.billing__container span {
  width: 20%;
  text-align: center;
}
.billing__container a {
  width: 20%;
  text-align: center;
}
.billing__container svg {
  cursor: pointer;
}

.billing-tabs-div {
  display: flex;
  flex-direction: row;
  width: calc(100% - 10px);
  margin-bottom: 4vh;
}

.upgrade-button {
  display: block;
  margin-top: 15px;
  border: none;
  border-radius: 30px;
  padding: 10px;
  color: white;
  font-size: 14px;
  background-color: #5047ff;
  min-width: 170px;
  cursor: pointer;
}
