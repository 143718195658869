.footer ul {
  display: flex;
  list-style: none;
}

.footer ul a {
  color: #4122af;
}

.footer .logo {
  display: inline-block;
  height: 20px;
}
