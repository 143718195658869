@import '../../../../variable.css';

.jobs-filters-container {
  padding-top: 12px;
  margin-bottom: 18px;
  border: 1px solid var(--color-light-grey);
  border-radius: var(--border-radius-form);
  background-color: var(--color-white);
  padding-left: 10px;
  padding-right: 10px;
}

.jobs-filters-container .filters-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.filters-row.row-fixed {
  grid-template-columns: repeat(auto-fit, minmax(100px, 400px));
}

.filters-row.row-fixed.row-command button {
  width: 170px;
}

/* Mobile */
@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .jobs-filters-container .filters-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .jobs-filters-container .filters-row:first-child {
    margin-bottom: 20px;
  }

  .filters-row.row-fixed.row-command button {
    margin: 20px 0;
  }
}
