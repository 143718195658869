.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.1);
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding-left: 2px;
  padding-top: 2px;
}

.alice-carousel {
  height: 100%;
  margin: 0;
}

.image__zoom > div {
  display: flex;
  width: fit-content !important;
}

.image__zoom > div > div {
  display: flex;
  justify-content: center;
  width: auto !important;
}

.carousel-controls .zoom {
  margin-top: 10px;
}

.zoom > a {
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (orientation: portrait) and (max-width: 600px),
  screen and (orientation: landscape) and (max-height: 600px) {
  .job-modal__main_carousel {
    width: 100%;
  }
}
.job-modal__main_carousel {
  height: 100%;
}
.highlight-svg {
  position: absolute;
  top: 0;
}
.footer-options {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: 10px;
}
.footer-options > .item {
  margin-right: 10px;
  cursor: pointer;
}
.footer-options > .item svg {
  display: block;
  height: 30px;
  width: 30px;
}
.zoom-icon svg {
  display: block;
  height: 35px;
  width: 35px;
}
.footer-options > .item:last-child {
  margin-right: 0px;
}
.rotate .image__zoom,
.rotate .image-zoom-content,
.rotate .div-1 {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}
.rotate .div-2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 98%;
}
.card-content {
  display: flex;
  flex-direction: column;
}

.zoom-icon {
  cursor: pointer;
}

.image-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-overlay img {
  display: block;
  width: auto;
  height: 70%;
  margin: 0 auto;
}

@media only screen and (min-width: 520px) {
  .zoom-icon svg {
    height: 40px;
    width: 40px;
  }
}
