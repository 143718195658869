.template-manager-container {
  width: 100%;
}

.template-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.fa-image.on {
  color: #090;
}

.highlight {
  background-color: red;
}

.sticky-image {
  position: sticky;
  top: 10px;
}
