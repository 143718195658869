:root {
  --border-radius-form: 4px;
  --font-size-base: 62.5%;
  --color-red: #ff0000;
  --color-dark-white: #fcfcfc;
  --color-white: #ffffff;
  --background-light-purple: #f0f0f6;
  --color-lightest-grey: #fafafa;
  --color-lighter-grey: #f4f6f7;
  --color-light-grey: #eaeaea;
  --color-grey: #7f90a0;
  --color-dark-grey: rgb(84, 107, 129);
  --color-darker-grey: rgb(41, 70, 97);
  --color-black: #294661;
  --color-blue: #2196f3;
  --color-lighter-purple: #dad2ed;
  --color-purple: #4122af;
  --color-dark-purple: #290e88;
  --color-orange: #ff9800;
  --color-green: rgb(75, 221, 82);
  --color-light-purple: #b9b7de;
  --color-dark-purple: #43426d;
  --min-width-base: 900px;
  --mobile-grid-font: 0.91rem;
}

/* global styles for fonts affected by new base font size */

div {
  font-size: 1.6rem;
}
.job-chart-mappings-colors {
  font-size: 1.17rem;
}
.MuiInputBase-root {
  font-size: 1.05625rem;
}

p {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.95rem;
}

h3 {
  font-size: 1.5rem;
}
