.job-charts-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 1200px;
}

.job-charts-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
